<template>
  <div>
    <Header></Header>
    <div class="main">
      <div class="wp">
        <Navbar></Navbar>

        <div class="white-bg ">
          <div class="news-main">
            <div class="div_ExamList_all com_Box">
              <div class="Tit">
                <div class="Tit-title">
                  常见问题
                </div>
              </div>
              <div class="List">
                <div v-for="(item, i) in list" :key="i" class="flexList div_enrollList" @click="openNews(item.newsId)">
                  <!--<div class="div_enrollList_tag" :class="item.isOpen ? 'tag-success' : 'tag-danger'"><span class="enrollList-text">{{item.isOpen ? '正在报名': '报名结束'}}</span></div>-->
                  <div class=" div_enrollList_center line1">{{item.newsTitle}}</div>
                  <!--<div class="div_ExamList_time"><i class="iconfont icon-shijian mr-2"></i>报名时间：{{item.bmkssj}}至{{item.bmjssj}}</div>-->
                  <!--<div class="div_ExamList_button  ExamList_button_info"><span class="enrollList-text">{{item.isOpen ? '正在报名': '报名结束'}}</span></div>-->
                </div>
              </div>
              <div style="padding: 30px;">
                <el-pagination
                        @size-change="handleSizeChange"
                        background
                        @current-change="handleCurrentChange"
                        :current-page="query.pageNum"
                        :page-size="query.pageSize"
                        layout="total, sizes,jumper, prev, pager, next"
                        :total="query.allCount"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </div>




      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
  import Header from "./index-header.vue"
  import Footer from "./index-footer.vue"
  import Navbar from "./navbar.vue"
  import commonPagination from "../common/commonPagination";

  import { getNewsListPage } from "@/api/web/web.js";

  export default {
    name: "index",
    data() {
      return {
        query: {
          nowPage: 1,
          pageSize: 20,
          allCount: 0,
          indexName: "cjwt", // 常见问题 大类索引名称
          isPaging: 1 // 分页请求
        },
        list: []
      }
    },
    components: {
      Header,
      Navbar,
      Footer,
      commonPagination
    },
    methods: {
      /**
       * 获取常见问题
       * @param params
       */
      getQuestion() {
        getNewsListPage(this.query).then((res) => {
          if (res.status) {
            let list = res.data
            for(var i=0;i<list.length;i++) {
              list[i].createTime = list[i].createTime.substring(0,10).replaceAll("-","/")
            }
            this.list = list
            this.query.allCount = res.total
          }
        });
      },

      /**
       * 打开新闻详情
       * */
      openNews(newsId) {
        let routeUrl = this.$router.resolve({
          path: "/newsDetails",
          query: {
            newsId: newsId,
          },
        });
        window.open(routeUrl.href, "_blank");
      },

      handleSizeChange(val) {
        this.query.pageSize = val;
        this.getQuestion();
      },
      handleCurrentChange(val) {
        this.query.pageNum = val;
        this.getQuestion();
      },
    },
    mounted() {
      this.getQuestion()
    }
  };
</script>

<style>
  @import '~@/assets/css/common.css';
  @import '~@/assets/css/index.css';
  @import '~@/assets/css/list.css';
</style>
<style type="text/css" scoped>
  .center-list-title .iconfont {
    color: #2886bd;
  }
  .news-main {
    min-height: 550px;
  }
</style>
